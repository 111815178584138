.principal {
  display: block;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.img {
  width: 9%;
  margin: 0 auto;
  height: auto;
  justify-content: center;
  text-align: center;
}

.textArea {
  font-size: 17.67px;
  font-family: "Open Sans", verdana;
  justify-content: center;
  text-align: center;
  margin-top: 25px;

}

.whatsApp {
  align-items: center;
  position: fixed;
  background-color: #25d366;
  color: #fff;
  border-radius: 60px;
  text-align: center;
  font-size: 43px;
  box-shadow: 1px 1px 2px #888;
  z-index: 1000;
  right: 2%;
  bottom: 2%;
}
.fa-whatsapp {
  width: 55px;
  height: 50px;

}

.fa-whatsapp:before {
  content: "\f232";
  font-size: 50px;

}

@media (min-width:320px) { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  principal {
    display: block;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  img {
    width: 420%;
    margin-left: -50px;
    justify-content: center;
    text-align: center;
  }

  textArea {
    font-size: 20px;
    font-family: "Open Sans", verdana;
    justify-content: center;
    text-align: center;
  }

  whatsApp {
    align-items: center;
    position: fixed;
    background-color: #25d366;
    color: #fff;
    border-radius: 60px;
    text-align: center;
    font-size: 45px;
    box-shadow: 1px 1px 2px #888;
    z-index: 1000;
    right: 2%;
    bottom: 2%;
  }
  fa-whatsapp {
    width: 55px;
    height: 50px;

  }

  fa-whatsapp:before {
    content: "\f232";
    font-size: 50px;

  }
}

@media (min-width: 480px) { /* smartphones, Android phones, landscape iPhone */
  principal {
    display: block;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  img {
    width: 495%;
    margin-left: -90px;
    justify-content: center;
    text-align: center;
  }

  textArea {
    font-size: 20px;
    font-family: "Open Sans", verdana;
    justify-content: center;
    text-align: center;
  }

  whatsApp {
    align-items: center;
    position: fixed;
    background-color: #25d366;
    color: #fff;
    border-radius: 60px;
    text-align: center;
    font-size: 45px;
    box-shadow: 1px 1px 2px #888;
    z-index: 1000;
    right: 2%;
    bottom: 2%;
  }
  fa-whatsapp {
    width: 55px;
    height: 50px;

  }

  fa-whatsapp:before {
    content: "\f232";
    font-size: 50px;
  }
}

@media (min-width: 600px) { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  principal {
    display: block;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  img {
    width: 420%;
    margin-left: -90px;
    justify-content: center;
    text-align: center;
  }

  textArea {
    font-size: 20px;
    font-family: "Open Sans", verdana;
    justify-content: center;
    text-align: center;
  }

  whatsApp {
    align-items: center;
    position: fixed;
    background-color: #25d366;
    color: #fff;
    border-radius: 60px;
    text-align: center;
    font-size: 45px;
    box-shadow: 1px 1px 2px #888;
    z-index: 1000;
    right: 2%;
    bottom: 2%;
  }
  fa-whatsapp {
    width: 55px;
    height: 50px;

  }

  fa-whatsapp:before {
    content: "\f232";
    font-size: 50px;
  }
}

@media (min-width: 801px) { /* tablet, landscape iPad, lo-res laptops ands desktops */
  principal {
    display: block;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  img {
    width: 350%;
    margin-left: -90px;
    justify-content: center;
    text-align: center;
  }

  textArea {
    font-size: 20px;
    font-family: "Open Sans", verdana;
    justify-content: center;
    text-align: center;
  }

  whatsApp {
    align-items: center;
    position: fixed;
    background-color: #25d366;
    color: #fff;
    border-radius: 60px;
    text-align: center;
    font-size: 45px;
    box-shadow: 1px 1px 2px #888;
    z-index: 1000;
    right: 2%;
    bottom: 2%;
  }
  fa-whatsapp {
    width: 55px;
    height: 50px;

  }

  fa-whatsapp:before {
    content: "\f232";
    font-size: 50px;
  }
}

@media (min-width:1025px) {/* big landscape tablets, laptops, and desktops */
  principal {
    display: block;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  img {
    width: 300%;
    margin-left: -90px;
    justify-content: center;
    text-align: center;
  }

  textArea {
    font-size: 20px;
    font-family: "Open Sans", verdana;
    justify-content: center;
    text-align: center;
  }

  whatsApp {
    align-items: center;
    position: fixed;
    background-color: #25d366;
    color: #fff;
    border-radius: 60px;
    text-align: center;
    font-size: 45px;
    box-shadow: 1px 1px 2px #888;
    z-index: 1000;
    right: 2%;
    bottom: 2%;
  }
  fa-whatsapp {
    width: 55px;
    height: 50px;

  }

  fa-whatsapp:before {
    content: "\f232";
    font-size: 50px;
  }
}

@media (min-width:1281px) { /* hi-res laptops and desktops */
  principal {
    display: block;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  img {
    width: 180%;
    margin-left: -53px;
    justify-content: center;
    text-align: center;
  }

  textArea {
    font-size: 20px;
    font-family: "Open Sans", verdana;
    justify-content: center;
    text-align: center;
  }

  whatsApp {
    align-items: center;
    position: fixed;
    background-color: #25d366;
    color: #fff;
    border-radius: 60px;
    text-align: center;
    font-size: 45px;
    box-shadow: 1px 1px 2px #888;
    z-index: 1000;
    right: 2%;
    bottom: 2%;
  }
  fa-whatsapp {
    width: 55px;
    height: 50px;

  }

  fa-whatsapp:before {
    content: "\f232";
    font-size: 50px;
  }
}
/*
@media (max-width: 759px) {
  @media (max-height: 845px) {
    principal {
      display: block;
      width: 100%;
      overflow-x: hidden;
      overflow-y: hidden;
    }

    img {
      width: 500%;
      margin-left: -90px;
      justify-content: center;
      text-align: center;
    }

    textArea {
      font-size: 20px;
      font-family: "Open Sans", verdana;
      justify-content: center;
      text-align: center;
    }

    whatsApp {
      align-items: center;
      position: fixed;
      background-color: #25d366;
      color: #fff;
      border-radius: 60px;
      text-align: center;
      font-size: 45px;
      box-shadow: 1px 1px 2px #888;
      z-index: 1000;
      right: 2%;
      bottom: 2%;
    }
    fa-whatsapp {
      width: 55px;
      height: 50px;

    }

    fa-whatsapp:before {
      content: "\f232";
      font-size: 50px;

    }
  }
}


@media (max-width: 414px) and (min-height: 896px) {
    principal {
      display: flex;
      width: 100%;
      overflow-x: hidden;
      overflow-y: hidden;
    }

    conteudo {
      padding-top: 100px;
    }
    img {
      width: 300%;
      margin-left: -95%;
      justify-content: center;
      text-align: center;
    }

    textArea {
      font-size: 20px;
      font-family: "Open Sans", verdana;
      justify-content: center;
      text-align: center;
      margin-top: 50px;
    }

    whatsApp {
      align-items: center;
      position: fixed;
      background-color: #25d366;
      color: #fff;
      border-radius: 60px;
      text-align: center;
      font-size: 45px;
      box-shadow: 1px 1px 2px #888;
      z-index: 1000;
      right: 2%;
      bottom: 2%;
    }
    fa-whatsapp {
      width: 55px;
      height: 50px;

    }

    fa-whatsapp:before {
      content: "\f232";
      font-size: 50px;

    }
  }


@media (min-width: 414px) {
  @media (max-height: 736px) {
    principal {
      display: block;
      overflow-x: hidden;
      overflow-y: hidden;
    }

    img {
      width: 220%;
      margin-left: -50px;
      display:flex;
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
    }

    textArea {
      font-size: 20px;
      font-family: "Open Sans", verdana;
      justify-content: center;
      text-align: center;
    }

    whatsApp {
      align-items: center;
      position: fixed;
      background-color: #25d366;
      color: #fff;
      border-radius: 60px;
      text-align: center;
      font-size: 45px;
      box-shadow: 1px 1px 2px #888;
      z-index: 1000;
      right: 2%;
      bottom: 2%;
    }
    fa-whatsapp {
      width: 55px;
      height: 50px;

    }

    fa-whatsapp:before {
      content: "\f232";
      font-size: 50px;

    }
  }
}

@media (max-width: 375px) {
  @media (min-height: 812px) {
    .principal {
      display: block;
      overflow-x: hidden;
      overflow-y: hidden;
    }

    img {
      width: 250%;
      margin-left: -50px;
      display:flex;
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
    }

    .textArea {
      font-size: 20px;
      font-family: "Open Sans", verdana;
      justify-content: center;
      text-align: center;
    }

    .whatsApp {
      align-items: center;
      position: fixed;
      background-color: #25d366;
      color: #fff;
      border-radius: 60px;
      text-align: center;
      font-size: 45px;
      box-shadow: 1px 1px 2px #888;
      z-index: 1000;
      right: 2%;
      bottom: 2%;
    }
    .fa-whatsapp {
      width: 55px;
      height: 50px;

    }

    .fa-whatsapp:before {
      content: "\f232";
      font-size: 50px;

    }
  }
}


@media (max-width: 375px) {
  @media (min-height: 667px) {
    .img {
      left: -105px;
      right: 150px;
      padding-top: 9px;
    }

    .textArea {
      font-size: 16px;
      padding-top: 0;
      justify-content: center;
      text-align: center;
    }

    .whatsApp {
      position: relative;
      width: 45px;
      height: 45px;
      margin-top: 70px;
      bottom: 3px;
      left: 310px;
      right: 50px;
      background-color: #25d366;
      color: #fff;
      border-radius: 50px;
      text-align: center;
      font-size: 30px;
      box-shadow: 1px 1px 2px #888;
      z-index: 1000;
    }
    .fa-whatsapp {
      margin-top: 8px;
    }
  }
}


@media (min-width: 360px) {
  @media (max-height: 740px) {
    .img {
      left: 110px;
      right: 150px;
      padding-top: 9px;
    }

    .textArea {
      font-size: 15px;
      padding-top: 20px;
      justify-content: center;
      text-align: center;
    }

    .whatsApp {
      position: relative;
      width: 45px;
      height: 45px;
      margin-top: 70px;
      bottom: 3px;
      left: 300px;
      right: 50px;
      background-color: #25d366;
      color: #fff;
      border-radius: 50px;
      text-align: center;
      font-size: 30px;
      box-shadow: 1px 1px 2px #888;
      z-index: 1000;
    }
    .fa-whatsapp {
      margin-top: 8px;
    }
  }
}
@media (max-width: 320px) {
  @media (min-height: 568px) {
    .img {
      left: 92px;
      right: 150px;
      top: -40px;
    }

    .textArea {
      font-size: 13.5px;
      justify-content: center;
      text-align: center;
    }

    .whatsApp {
      position: relative;
      width: 45px;
      height: 45px;
      margin-top: -24px;
      bottom: 3px;
      left: 260px;
      right: 50px;
      background-color: #25d366;
      color: #fff;
      border-radius: 50px;
      text-align: center;
      font-size: 30px;
      box-shadow: 1px 1px 2px #888;
      z-index: 1000;
    }
    .fa-whatsapp {
      margin-top: 15px;
    }
  }
}

@media (max-width: 320px) {
  @media (min-height: 534px) {
    .img1 {
      margin-right: 130px;
      margin-left: -356px;
      margin-top: -38px;
      padding-top: 9px;
    }
    .img1,
    .img2 {
      flex-direction: row;
      display: block;
      position: center;
      justify-content: center;
      text-align: center;
    }
    .img2 {
      margin-left: -310px;
    }

    .textArea {
      font-size: 12.62px;
      padding-top: 0;
      justify-content: center;
      text-align: center;
    }

    .whatsApp {
      position: fixed;
      width: 35px;
      height: 36px;
      top: 497px;
      bottom: 3px;
      right: 5px;
      background-color: #25d366;
      color: #fff;
      border-radius: 50px;
      text-align: center;
      font-size: 30px;
      box-shadow: 1px 1px 2px #888;
      z-index: 1000;
    }
    .fa-whatsapp {
      margin-top: 1px;
    }
  }
}
*/
